<template>
  <Component />
</template>

<script setup lang="ts">
  import { LayoutNavigationItemIcon, NuxtLink } from "#components";
  const route = useRoute();

  defineOptions({
    name: "LayoutNavigationItem",
  });

  const props = withDefaults(
    defineProps<{
      entry: ISidebarEntry | ISidebarAction;
      expanded: boolean;
    }>(),
    {
      expanded: false,
    }
  );

  const Component = computed<any>(() => {
    let classes =
      "h-[38px] focus:shadow-focus-blue group flex w-full items-center gap-1.5 whitespace-nowrap rounded-[10px] p-1 text-body-default text-secondary hover:text-primary hover:bg-01 transition-all focus:text-gray-900 focus:outline-none active:outline-none ";

    let iconClasses = "text-secondary flex-shrink-0 rounded-[10px]";

    if (route.path.includes(props.entry.route_key)) {
      classes += 'active bg-surface-lvl-00 border border-01 shadow-lvl-01';
    }

    if (!props.expanded) {
      classes += " !w-[38px]";
    }

    const componentProps = {
      class: classes,
    };

    if (props.entry.route_key) {
      return h(
        NuxtLink,
        {
          ...componentProps,
          href: props.entry.route,
          target: props.entry.target || "_self",
        },
        () => [
          h(LayoutNavigationItemIcon, {
            name: props.entry.icon,
            class: iconClasses,
          }),
          h("span", { class: "px-1" }, props.entry.label),
        ]
      );
    }

    return h("button", {...componentProps, onClick: props.entry.action, type: 'button'}, [
      h(LayoutNavigationItemIcon, {
        name: props.entry.icon,
        class: iconClasses,
      }),
      h("span", { class: "px-1" }, props.entry.label),
    ]);
  });
</script>
