<template>
  <LayoutNavigationWorkspaceDropdown v-slot="slotProps">
    <div
      class="dropdown-toggle relative flex cursor-pointer items-center gap-2.5 p-1 rounded-[10px] hover:bg-01"
      :class="{
        'w-[190px]': expanded,
        'w-9': !expanded,
        'bg-surface-lvl-00 shadow-lvl-01': slotProps.open,
      }"
    >
      <LayoutNavigationWorkspaceAvatar size="2xs"/>
      <div
        class="mr-auto truncate text-body-default-heavy text-primary"
      >
        {{ account.account.name }}
      </div>
      <div class="flex items-center">
        <Icon
          src="bold/CaretDown"
          size="small"
          class="transition-transform"
          :class="{
            '-rotate-90 transform': slotProps.open,
          }"
        />
      </div>
    </div>
  </LayoutNavigationWorkspaceDropdown>
</template>

<script setup lang="ts">
  defineOptions({
    name: "LayoutNavigationWorkspace",
  });

  const props = withDefaults(
    defineProps<{
      expanded: boolean;
    }>(),
    {
      expanded: false,
    }
  );
  const account = useAuth().getAccount();
</script>
