<template>
  <aside
    class="z-50 h-full overflow-x-hidden bg-surface-lvl-01 transition-all"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div
      class="flex h-full flex-col transition-all py-1.5 "
      :class="[expanded ? 'w-sidebar-open' : 'w-sidebar-closed']"
    >
      <div class="px-1.5">
        <slot name="header"></slot>
      </div>

      <nav
        class="w-sidebar-open flex-grow px-1.5 pb-4 pt-2 transition-all md:overflow-y-auto"
      >
        <slot></slot>
      </nav>
      <div class="px-1.5">

      <slot name="footer"></slot>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
  import { computed, provide, ref } from "vue";

  const hover = ref(false);
  const openEntry = ref();
  const expanded = computed(
    () => hasOpenDropdown.value || hover.value || locked.value
  );
  const hasOpenDropdown = ref(false);
  const hoverTimeout = ref();
  const locked = ref(false);

  function toggle() {
    hover.value = !hover.value;
  }

  function lock() {
    locked.value = !locked.value;
  }

  useDoubleClick({
    key: "Control",
    callback: () => {
      lock();
    },
  });

  const onMouseEnter = () => {
    //wait 250ms before opening the sidebar
    hoverTimeout.value = setTimeout(() => {
      hover.value = true;
    }, 250);
  };

  const onMouseLeave = () => {
    clearTimeout(hoverTimeout.value);
    hover.value = false;
  };

  provide("navigationBarOpen", {
    open: hasOpenDropdown,
    toggleNavigationBar: (val: boolean) =>
      (hasOpenDropdown.value = val),
  });

  defineExpose({
    expanded,
    hover,
    openEntry,
    hasOpenDropdown,
    toggle,
    lock,
    locked,
  });
</script>
